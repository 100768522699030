import React, { Component } from "react";
import Header from "../Header";
const Service = () => {
  return (
    <>
      <Header />
      <h1>Service</h1>
    </>
  );
};
export default Service;
