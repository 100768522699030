import React, { Component, useState } from "react";
import Header from "../Header";
import Footer from "../Footer/Footer";
import pricecatalog from "../pricelist.json";
const PriceList = () => {
  const [price, setPrice] = useState(pricecatalog);
  const [priceList, setPriceList] = useState([
    {
      image: "image",
      Name: "name",
      price: "pricelist",
    },
  ]);
  const handleAddProduct = () => {
    const newSheet = {
      name: `sheet${priceList.length}`,
      image: "image",
      name: "name",
      price: "pricelist",
    };
    setPriceList((prev) => [...prev, newSheet]);
  };
  const handleClick = (index) => {
    console.log(index);
    let prices = pricecatalog[index].Price;
    pricecatalog[index].Price = 99;
    console.log(pricecatalog[index].Price);
  };
  return (
    <>
      <Header />
      <div className="" style={{ background: "" }}>
        <h1 style={{ marginLeft: "30px" }}> Price list</h1>
        <p
          style={{
            margin: "30px",
          }}
        >
          The following is a list of items being sold:
        </p>
        {/* <button onClick={handleAddProduct}>Add Product</button> */}
        <div>
          <div className="PriceContainer1">
            <div className="priceCon1">
              <img
                src="./newspaper.jpg"
                style={{ width: "200px", height: "200px" }}
              />
              <p className="priceTag">12rs per kg</p>
              <p className="ProductName">Newspaper</p>
            </div>
            <div className="priceCon1">
              <img
                src="./books.jpg"
                style={{ width: "200px", height: "200px" }}
              />
              <p className="priceTag">8rs per kg</p>
              <p className="ProductName">Books</p>
            </div>
            <div className="priceCon1">
              <img
                src="./cardboard.jpg"
                style={{ width: "200px", height: "200px" }}
              />
              <p className="priceTag">4rs per kg</p>
              <p className="ProductName">Carton Box</p>
            </div>
            <div className="priceCon1">
              <img
                src="./plastic.jpg"
                style={{ width: "200px", height: "200px" }}
              />
              <p className="priceTag">5rs per kg</p>
              <p className="ProductName">Plastic</p>
            </div>
            <div className="priceCon1">
              <img
                src="./hardplastic.jpg"
                style={{ width: "200px", height: "200px" }}
              />
              <p className="priceTag">2rs per kg</p>
              <p className="ProductName">Hard Plastic</p>
            </div>
            <div className="priceCon1">
              <img
                src="./sheet.jpg"
                style={{ width: "200px", height: "200px" }}
              />
              <p className="priceTag">16rs per kg</p>
              <p className="ProductName">Sheet</p>
            </div>
            <div className="priceCon1">
              <img
                src="./iron.jpg"
                style={{ width: "200px", height: "200px" }}
              />
              <p className="priceTag">25rs per kg</p>
              <p className="ProductName">Iron</p>
            </div>
            <div className="priceCon1">
              <img
                src="./brass.jpg"
                style={{ width: "200px", height: "200px" }}
              />
              <p className="priceTag">300rs per kg</p>
              <p className="ProductName">Brass</p>
            </div>
            <div className="priceCon1">
              <img
                src="./copper.jpg"
                style={{ width: "200px", height: "200px" }}
              />
              <p className="priceTag">410 per kg</p>
              <p className="ProductName">Cooper</p>
            </div>
            <div className="priceCon1">
              <img
                src="./steel.jpg"
                style={{ width: "200px", height: "200px" }}
              />
              <p className="priceTag">40rs per kg</p>
              <p className="ProductName">Steel</p>
            </div>
            <div className="priceCon1">
              <img
                src="./Aluminum.jpg"
                style={{ width: "200px", height: "200px" }}
              />
              <p className="priceTag">100rs per kg</p>
              <p className="ProductName">Aluminium</p>
            </div>
            <div className="priceCon1">
              <img
                src="./Electronicwaste.jpg"
                style={{ width: "200px", height: "200px" }}
              />
              <p className="priceTag">15rs per kg</p>

              <p className="ProductName">Electronic Waste</p>
            </div>
            <div className="priceCon1">
              <img
                src="./mobilephones.jpg"
                style={{ width: "200px", height: "200px" }}
              />
              <p className="priceTag">15rs per kg</p>
              <p className="ProductName">Mobile phones</p>
            </div>
            <div className="priceCon1">
              <img
                src="./laptop.jpg"
                style={{ width: "200px", height: "200px" }}
              />
              <p className="priceTag">200-600 per peice</p>
              <p className="ProductName">Laptop</p>
            </div>
            <div className="priceCon1">
              <img
                src="./lcd.jpg"
                style={{ width: "200px", height: "200px" }}
              />
              <p className="priceTag">15rs per kg</p>
              <p className="ProductName">LCD Monitor</p>
            </div>
            <div className="priceCon1">
              <img
                src="./crtImage.jpg"
                style={{ width: "200px", height: "200px" }}
              />
              <p className="priceTag">100-150 per piece</p>
              <p className="ProductName">CRT Monitor</p>
            </div>
            <div className="priceCon1">
              <img
                src="./lcd_led.jpg"
                style={{ width: "200px", height: "200px" }}
              />
              <p className="priceTag">100-150rs</p>
              <p className="ProductName">LCD/LED Television</p>
            </div>
            <div className="priceCon1">
              <img
                src="./crtTelevision.jpg"
                style={{ width: "200px", height: "200px" }}
              />
              <p className="priceTag">100-150rs</p>
              <p className="ProductName">CRT Television</p>
            </div>
            <div className="priceCon1">
              <img src="./cd.jpg" style={{ width: "200px", height: "200px" }} />
              <p className="priceTag">5rs per kg</p>

              <p className="ProductName">CD</p>
            </div>
            <div className="priceCon1">
              <img
                src="./wire.jpg"
                style={{ width: "200px", height: "200px" }}
              />
              <p className="priceTag">50rs per kg</p>
              <p className="ProductName">Wire</p>
            </div>
            <div className="priceCon1">
              <img
                src="./battery.jpg"
                style={{ width: "200px", height: "200px" }}
              />
              <p className="priceTag">85rs per kg</p>
              <p className="ProductName">Battery</p>
            </div>
            <div className="priceCon1">
              <img
                src="./splitAc.jpg"
                style={{ width: "200px", height: "200px" }}
              />
              <p className="priceTag">2000-3000 per pieceg</p>
              <p className="ProductName">Split AC</p>
            </div>
            <div className="priceCon1">
              <img
                src="./windowAc.jpg"
                style={{ width: "200px", height: "200px" }}
              />
              <p className="priceTag">2000-3000 per piece</p>
              <p className="ProductName">Window Ac</p>
            </div>
            <div className="priceCon1">
              <img
                src="./cooler.jpg"
                style={{ width: "200px", height: "200px" }}
              />
              <p className="priceTag">100-200rs per piece</p>

              <p className="ProductName">Cooler</p>
            </div>
            <div className="priceCon1">
              <img
                src="./fridge.jpg"
                style={{ width: "200px", height: "200px" }}
              />
              <p className="priceTag">500-1000 per piece</p>
              <p className="ProductName">Fridge</p>
            </div>
            <div className="priceCon1">
              <img
                src="./microwave.jpg"
                style={{ width: "200px", height: "200px" }}
              />
              <p className="priceTag">200-300 per piece</p>
              <p className="ProductName">Microwave</p>
            </div>
            <div className="priceCon1">
              <img
                src="./dishwasher.jpg"
                style={{ width: "200px", height: "200px" }}
              />
              <p className="priceTag">300-600 per piece</p>

              <p className="ProductName">Dish Washer</p>
            </div>
            <div className="priceCon1">
              <img
                src="./washingmachine.jpg"
                style={{ width: "200px", height: "200px" }}
              />
              <p className="priceTag">400-800 per piece</p>

              <p className="ProductName">Washing Machine (Front Load)</p>
            </div>
            <div className="priceCon1">
              <img
                src="./washingmachine_topload.jpg"
                style={{ width: "200px", height: "200px" }}
              />
              <p className="priceTag">400-500 per piece</p>
              <p className="ProductName">Washing Machine (Top Load)</p>
            </div>
            <div className="priceCon1">
              <img
                src="./geyser.jpg"
                style={{ width: "200px", height: "200px" }}
              />
              <p className="priceTag">50-300rs per piece</p>

              <p className="ProductName">Geyser</p>
            </div>
            <div className="priceCon1">
              <img
                src="./vehicleTypres.jpg"
                style={{ width: "200px", height: "200px" }}
              />
              <p className="priceTag">3rs per kg</p>

              <p className="ProductName">Vehicle Tyres</p>
            </div>
            <div className="priceCon1">
              <img
                src="./mixwaste.jpg"
                style={{ width: "200px", height: "200px" }}
              />
              <p className="priceTag">5rs per kg</p>

              <p>Mix Waste</p>
            </div>
            <div className="priceCon1">
              <img
                src="./furnitureItems.jpg"
                style={{ width: "200px", height: "200px" }}
              />
              <p className="priceTag">0rs(Donation)</p>
              <p className="ProductName">Furniture Items(Good Condition)</p>
            </div>
            <div className="priceCon1">
              <img
                src="./clothes.jpg"
                style={{ width: "200px", height: "200px" }}
              />
              <p className="priceTag">0rs (Donation)</p>

              <p className="ProductName">Clothes</p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default PriceList;
