import React from "react";
import { SocialIcon } from "react-social-icons/component";
import "react-social-icons/instagram";
import "react-social-icons/whatsapp";
import { Link } from "react-router-dom";
import { IoCall } from "react-icons/io5";
import { MdOutlineMailOutline } from "react-icons/md";
import FAQS from "../FAQS";

export default function Footer() {
  return (
    <div className="FooterMain">
      <div className="Footer">
        <div className="footer-div">
          <h4>Services</h4>
          <p>Pick up </p>
          <p>Recycle </p>
          <p>
            <Link className="btnLink" to={"/FAQS"}>
              FAQs
            </Link>
          </p>
        </div>
        <div className="footer-div">
          <h4>Contact</h4>
          <p>
            <IoCall />
            +91 9966353131
          </p>
          <p>
            <IoCall />
            +918801829760
          </p>
          <p>
            <MdOutlineMailOutline />
            recrap.spprt@gmail.com
          </p>
        </div>
        <div className="footer-div">
          <h4>Address</h4>
          Shop No 2-2-1088/1,<br></br> Golnaka - Amberpet, Hyderabad Telangana
          500013
        </div>
        <div className="footer-div">
          <h4>Connect</h4>
          <br></br>
          <Link
            className="btnLink"
            to={"https://wa.me/+919966353131"}
            style={{ margin: "10px" }}
          >
            <SocialIcon network="whatsapp" />
          </Link>
          <SocialIcon url="https://www.instagram.com/re.crap/" />
        </div>
        <div>(c) 2023 Recrap. All rights reserved.</div>
      </div>
    </div>
  );
}
