import React, { Component } from "react";
import Header from "../Header";
import Footer from "../Footer/Footer";

const About = () => {
  return (
    <>
      <Header />
      <div className="home" style={{ height: "auto" }}>
        <div className="aboutPageText">
          <h1 style={{ marginLeft: "30px" }}>About us</h1>
          <p className="aboutPageT">
            <p style={{ margin: "10px" }}>
              At Recrap, we believe that there's untapped value in the items you
              no longer need. In a world increasingly aware of health,
              convenience, and the environment, we've taken the initiative to
              dive into the recycling industry.
            </p>
            <br></br>
            <p style={{ margin: "10px" }}>
              With modern lifestyles and the rising importance of
              eco-friendliness, we understand the need for an efficient and
              rewarding solution. That's why we've created Recrap. Our mission
              is simple: to help you turn your trash into cash.
            </p>
            <br></br>
            <p style={{ margin: "10px" }}>
              Through careful research and dedication to environmental
              sustainability, we've established a seamless process. We make it
              easy for you to recycle your waste, making a positive impact on
              your wallet and our planet.
            </p>
            <br></br>
            <p style={{ margin: "10px" }}>
              Join us on this journey towards a cleaner, more prosperous future.
              Choose Recrap and see your waste transform into wealth!
            </p>
          </p>
        </div>
        <img src="../draw.png" className="aboutPAgeImg" />
      </div>
      <Footer />
    </>
  );
};

export default About;
