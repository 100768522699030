import React, { useRef, useState } from "react";
import Header from "./Header";
import { Link } from "react-router-dom";
import Footer from "./Footer/Footer";
import "react-social-icons/whatsapp";
import { SocialIcon } from "react-social-icons/component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import emailjs from "@emailjs/browser";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import ReactSimplyCarousel from "react-simply-carousel";

import { Carousel } from "react-responsive-carousel";

const LandingPage = () => {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    var frm = document.getElementsByName("contact-form")[0];
    emailjs
      .sendForm(
        "service_0qodi5o",
        "template_qg3i4h7",
        form.current,
        "9Gnctdfm50jjv54w9"
      )
      .then(
        (result) => {
          if (result.text === "OK") {
            toast("Thank you we will get back to you");
            frm.reset();
          }
        },
        (error) => {
          console.log(error.text);
        }
      );
    return false; // Prevent page refresh
  };
  return (
    <>
      <Header />

      <Link
        className="btnLink1"
        to={"https://wa.me/+919966353131"}
        style={{ margin: "10px" }}
      >
        <SocialIcon
          network="whatsapp"
          style={{ position: "fixed", left: "97%", float: "right" }}
          className="icon"
        />
      </Link>

      <Link
        className="btnLink2"
        to={"https://wa.me/+919966353131"}
        style={{ margin: "10px" }}
      >
        <SocialIcon
          network="whatsapp"
          style={{ position: "fixed", left: "89%", float: "right" }}
          className="icon"
        />
      </Link>

      <div className="home" style={{ height: "500px" }}>
        <div>
          <p className="HeadLine">
            Take a Step to Recycle your
            <small className="wordScrap">Scrap</small>
          </p>
          <p className="line2">
            Motivating users to make more sustainable choices by rewarding
            <br></br> waste reduction.
          </p>

          <Link className="btnLink" to={"https://wa.me/+919966353131"}>
            <button className="Btn">Get In Touch</button>
          </Link>
          {/* <a aria-label="Chat on WhatsApp" href="https://wa.me/+918801829760">
            <img alt="Chat on WhatsApp" src="../WhatsAppButtonGreenSmall.png" />
          </a> */}
        </div>
        <div className="img-1">
          <img src="../hero-img.png" className="img-1" />
        </div>
      </div>
      <div className="works" style={{ height: "400px" }}>
        <div>
          <h1
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              marginBottom: "70px",
            }}
          >
            How this Works?
          </h1>
        </div>
        <div className="service">
          <div className="steps">
            <img className="ImgWork" src="../book (1).png" />
            <p className="step">Place an order for pickup</p>
          </div>
          <div className="steps">
            <img className="ImgWork" src="../truck (1).png" />
            <p className="step">Our team will collect your stuff</p>
          </div>
          <div className="steps">
            <img className="" src="../earn (1).png" />
            <p className="step">You get paid instantly</p>
          </div>
        </div>
      </div>
      <div className="home">
        <div className="aboutText1">
          <h1
            style={{
              marginLeft: "12px",
            }}
          >
            About us
          </h1>
          <p className="aboutText">
            In a world that values health, convenience, and the environment,
            we've ventured into waste recycling. Introducing Recrap: where your
            discarded items forge a richer, cleaner tomorrow. A wonderful
            opportunity to turn your waste into wealth.
            <br></br> Discover the value in your waste and join us on the
            journey to a cleaner and healthy planet!
            <br></br>
            <Link className="btnLink" to={"/about"}>
              <button
                className="Btn"
                style={{ marginTop: "10px", marginLeft: "0px" }}
              >
                Know More...
              </button>
            </Link>
          </p>
        </div>
        <img src="../about.png" className="aboutImg" />
      </div>
      <div className="PriceList" style={{}}>
        <h1 style={{ marginLeft: "55px", marginBottom: "10px" }}>Price List</h1>

        <ReactSimplyCarousel
          activeSlideIndex={activeSlideIndex}
          onRequestChange={setActiveSlideIndex}
          itemsToShow={1}
          itemsToScroll={1}
          forwardBtnProps={{
            //here you can also pass className, or any other button element attributes
            style: {
              alignSelf: "center",
              background: "black",
              border: "none",
              borderRadius: "50%",
              color: "white",
              cursor: "pointer",
              fontSize: "20px",
              height: 30,
              lineHeight: 1,
              textAlign: "center",
              width: 30,
            },
            children: <span>{`>`}</span>,
          }}
          backwardBtnProps={{
            //here you can also pass className, or any other button element attributes
            style: {
              alignSelf: "center",
              background: "black",
              border: "none",
              borderRadius: "50%",
              color: "white",
              cursor: "pointer",
              fontSize: "20px",
              height: 30,
              lineHeight: 1,
              textAlign: "center",
              width: 30,
            },
            children: <span>{`<`}</span>,
          }}
          responsiveProps={[
            {
              itemsToShow: 3,
              itemsToScroll: 2,
              minWidth: 768,
            },
          ]}
          speed={400}
          easing="linear"
        >
          {/* here you can also pass any other element attributes. Also, you can use your custom components as slides */}

          <div className="priceCon">
            <img
              src="./books.jpg"
              style={{ width: "200px", height: "200px" }}
            />
            <p className="priceTag">8rs per kg</p>
            <p className="ProductName">Books</p>
          </div>
          <div className="priceCon">
            <img
              src="./cardboard.jpg"
              style={{ width: "200px", height: "200px" }}
            />
            <p className="priceTag">4rs per kg</p>
            <p className="ProductName">Carton Box</p>
          </div>
          <div className="priceCon">
            <img
              src="./copper.jpg"
              style={{ width: "200px", height: "200px" }}
            />
            <p className="priceTag">410 per kg</p>
            <p className="ProductName">Cooper</p>
          </div>
          <div className="priceCon">
            <img
              src="./fridge.jpg"
              style={{ width: "200px", height: "200px" }}
            />
            <p className="priceTag">500-1000 per piece</p>
            <p className="ProductName">Fridge</p>
          </div>
          <div className="priceCon">
            <img
              src="./geyser.jpg"
              style={{ width: "200px", height: "200px" }}
            />
            <p className="priceTag">50-300rs per piece</p>

            <p className="ProductName">Geyser</p>
          </div>

          <div className="priceCon">
            <img src="./cd.jpg" style={{ width: "200px", height: "200px" }} />
            <p className="priceTag">5rs per kg</p>

            <p className="ProductName">CD</p>
          </div>
          <div className="priceCon">
            <img src="./wire.jpg" style={{ width: "200px", height: "200px" }} />
            <p className="priceTag">50rs per kg</p>
            <p className="ProductName">Wire</p>
          </div>
        </ReactSimplyCarousel>
      </div>
      <Link className="btnLink" to={"/priceList"}>
        <button className="viewBtn">View More</button>
      </Link>
      <div className="ConactPage">
        <div>
          <ToastContainer />

          <h1 style={{ marginLeft: "25px" }}> Contact us</h1>
          <form
            ref={form}
            name="contact-form"
            className="ContactForm"
            onSubmit={sendEmail}
          >
            <label>Name</label>
            <input
              type="text"
              name="name"
              placeholder=" Enter Your Name"
              required
            />
            <label>Phone Number</label>
            <input
              type="Number"
              name="number"
              placeholder="Enter Phone Number"
              required
            />
            <label>Email</label>
            <input
              type="email"
              name="email"
              placeholder="Enter email address"
              required
            />
            <label>Message</label>
            <input name="message" placeholder="Enter your Message" required />
            <button type="submit" value="Send" className="Btn">
              Submit
            </button>
          </form>
        </div>
        <div>
          <img src="../signup (1).png" className="conactImage" />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default LandingPage;
