import React, { useRef } from "react";
import Header from "../Header";
import emailjs from "@emailjs/browser";
import Footer from "../Footer/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    var frm = document.getElementsByName("contact-form")[0];

    emailjs
      .sendForm(
        "service_0qodi5o",
        "template_qg3i4h7",
        form.current,
        "9Gnctdfm50jjv54w9"
      )
      .then(
        (result) => {
          if (result.text === "OK") {
            toast("Thank you we will get back to you");
            frm.reset();
          }
        },
        (error) => {
          console.log(error.text);
        }
      );
    return false; // Prevent page refresh
  };
  const notify = () => toast("Wow so easy!");

  return (
    <>
      <Header />
      <ToastContainer />
      <div className="ConactPage">
        <div>
          <h1> Contact us</h1>
          <form
            ref={form}
            name="contact-form"
            className="ContactForm"
            onSubmit={sendEmail}
          >
            <label>Name</label>
            <input
              type="text"
              name="name"
              placeholder="Enter Your Name"
              required
            />
            <label>Phone Number</label>
            <input
              type="Number"
              name="number"
              placeholder="Enter Phone Number"
              required
            />
            <label>Email</label>
            <input
              type="email"
              name="email"
              placeholder="Enter email address"
              required
            />
            <label>Message</label>
            <input name="message" placeholder="Enter your Message" required />
            <button type="submit" value="Send" className="Btn">
              Submit
            </button>
          </form>
        </div>
        <div>
          <img src="../signup (1).png" className="conactImage" />
        </div>
      </div>
      <Footer />
    </>
  );
};
export default Contact;
