import React from "react";
import questions from "./faq.json";
import breed from "./pricelist.json";
import Header from "./Header";
import Banner from "./Banner";
import Footer from "./Footer/Footer";
const FAQS = () => {
  return (
    <>
      <Header />

      <Banner>
        <Banner.Header>Frequently Asked Questions</Banner.Header>
        {questions.map((question) => (
          <Banner.Entity key={question.id}>
            <Banner.Question>{question.question}</Banner.Question>
            <Banner.Text>{question.answer}</Banner.Text>
          </Banner.Entity>
        ))}
      </Banner>
      <Footer />
    </>
  );
};
export default FAQS;
