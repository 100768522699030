import "./App.css";
import { Routes, Route } from "react-router-dom";
import LandingPage from "./Components/LandingPage";
import About from "./Components/About/About";
import Service from "./Components/Service/Service";
import PriceList from "./Components/PriceList/PriceList";
import Contact from "./Components/Contact/Contact";
import FAQS from "./Components/FAQS";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/About" element={<About />} />
        <Route path="/FAQS" element={<FAQS />} />

        <Route path="/Service" element={<Service />} />
        <Route path="/PriceList" element={<PriceList />} />
        <Route path="/Contact" element={<Contact />} />
      </Routes>
    </div>
  );
}

export default App;
