import React, { useState } from "react";
import { Link } from "react-router-dom";
import { IoCall } from "react-icons/io5";
import { MdOutlineMailOutline } from "react-icons/md";
import Hamburger from "hamburger-react";

const Header = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <div className="headNumbers" style={{ background: "black" }}>
        <p>
          <IoCall />
          +91 9966353131
        </p>
        /
        <p>
          <IoCall />
          +918801829760
        </p>
        /
        <p>
          <MdOutlineMailOutline />
          recrap.spprt@gmail.com
        </p>
      </div>
      <div className="header">
        <div className="Logo">
          <img src="../Vector.png" style={{ width: "50px" }} />
          <Link className="Logo" to="/">
            <small style={{ color: "green", fontSize: "40px" }}>Re</small>
            <h1 style={{ color: "black", fontWeight: "200", fontSize: "40px" }}>
              crap
            </h1>
          </Link>
          <div className="toggleBtn">
            <Hamburger toggled={isOpen} toggle={setOpen} />
          </div>
        </div>

        <div className="navbar">
          <li>
            <Link className="linkHeader" to="/">
              Home
            </Link>
          </li>
          <li>
            <Link className="linkHeader" to="/about">
              About
            </Link>
          </li>
          {/* <li>
            <Link className="linkHeader" to="/Service">
              Service
            </Link>
          </li> */}
          <li>
            <Link className="linkHeader" to="/PriceList">
              Price List
            </Link>
          </li>
          <li>
            <Link className="linkHeader" to="/Contact">
              Contact us
            </Link>
          </li>
        </div>
        <div
          className="navbarMobile"
          style={{
            display: isOpen ? "block" : "none",
            transform: isOpen ? "translateX(0)" : "translateX(100%)",
            transition: "transform 0.3s ease-in-out",
            zIndex: isOpen ? 1000 : 0,
          }}
        >
          <li>
            <Link className="linkHeader" to="/">
              Home
            </Link>
          </li>
          <li>
            <Link className="linkHeader" to="/about">
              About
            </Link>
          </li>
          {/* <li>
            <Link className="linkHeader" to="/Service">
              Service
            </Link>
          </li> */}
          <li>
            <Link className="btnLink" to={"/FAQS"}>
              FAQs
            </Link>
          </li>
          <li>
            <Link className="linkHeader" to="/PriceList">
              Price List
            </Link>
          </li>
          <li>
            <Link className="linkHeader" to="/Contact">
              Contact us
            </Link>
          </li>
        </div>
      </div>
    </>
  );
};

export default Header;
